import { AutoLoginData } from "@/types/authorization/AutoLoginData";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { baseAlert } from "@/alerts/baseAlert";
import router from "@/router";
import { RouteNames } from "@/router/RouteNames";
import { LoginResponse } from "@/types/authorization/LoginResponse";
import { getErrorMessage } from "bright-logic-core/src/errors";
import { inject } from "vue";
import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { LoginDetails } from "@/types/authentication/LoginDetails";
import { AuthenticationCookieManager } from "@/services/AuthenticationCookieManager";
import { AcquaintApiNextClient } from "@/services/AcquaintApiNextClient";
import displayErrorMessage from "@/utils/ErrorHandling";
import { AutoLoginAction } from "@/enums/system/AutoLoginAction";

export const useLoginStore = defineStore('loginStore', () => {
  const autoLoginData = ref<AutoLoginData>();
  const acquaintApiClient = inject('AcquaintApiClient') as AcquaintApiClient;
  const authenticationCookieManager = reactive(new AuthenticationCookieManager());
  const acquaintApiNextClient = inject('AcquaintApiNextClient') as AcquaintApiNextClient;

  const login = async (credentials: LoginDetails) => {
    if (baseAlert.isVisible()) {
      baseAlert.close();
    }
    // Login to Acquaint using the form details
    try {
      const authenticationToken = await acquaintApiClient.httpPost<LoginResponse>("/app/auth/login", credentials)

      // Store the user's connector token in the cookie 
      authenticationCookieManager.storeLogin(authenticationToken);
      authenticationCookieManager.storeSiteDetails({
        sitePrefix: credentials.sitePrefix,
        appPassword: credentials.appPassword,
        userName: credentials.userName,
        rememberMe: credentials.rememberMe,
        siteName: '',
        pcid: authenticationToken.pcid
      });

      /* To avoid multiple pc records being created for the same device by the new api
       * We must set the pc id to the pc id returned by the old api */
        credentials.pcid = authenticationToken.pcid;      
    } catch (err) {
      displayErrorMessage(getErrorMessage(err));
      return false;
    }

    await loginToNewAcquaintApi(credentials);
   
    router.push({ name: RouteNames.Home });

    return true;
  }

  const loginToNewAcquaintApi = async (credentials: LoginDetails) => {
    try {
      await acquaintApiNextClient.authenticate(credentials);
    } catch (err) {
      console.error(getErrorMessage(err));
    }
  }

  const autoLogin = async (data: string) => {
    const response = await acquaintApiClient.httpPost<AutoLoginData>('app/auth/validate', {
      data: data
    });

    autoLoginData.value = response;

    const credentials: LoginDetails = {
      sitePrefix: response.sitePrefix,
      appPassword: response.appPassword,
      userName: response.userName,
      userPassword: response.userPassword,
      rememberMe: false
    }

    return await login(credentials);
  }

  const getAutoLoginData = () => autoLoginData.value;

  const resetAutoLoginAction = () => {
    if (!autoLoginData.value) {
      return;
    }

    autoLoginData.value.action = AutoLoginAction.None;
  };

  return { getAutoLoginData, login, autoLogin, resetAutoLoginAction }
});